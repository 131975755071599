* {
  text-decoration: none;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}


:root {
  --primary-color: #231d85;
  --text-color: #030303;
  --text-contrast: #ffffff;
}

::selection {
  background-color: var(--primary-color);
  color: var(--text-contrast);
}

::-moz-selection {
  background-color: var(--primary-color);
  color: var(--text-contrast);
}

body {
  margin: 0;
  min-height: 100vh;
  background-color: var(--text-contrast);
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
}

main {
  flex: 1 0 auto;
  color: var(--text-color);
  background-size: cover;
  background-attachment: fixed;
  position: relative;
}

main * {
  font-size: 18px;
}

.button1 {
  color: var(--text-contrast);
  background-color: var(--primary-color);
  white-space: nowrap;
  padding: 10px;
  border: 1px var(--primary-color) solid;
  cursor: pointer;
  width: fit-content;
  transition: 200ms;
}

.button1:hover {
  color: var(--primary-color);
  background-color: var(--text-contrast);
  white-space: nowrap;
  padding: 10px;
  border: 1px var(--primary-color) solid;
}


.button2 {
  color: var(--primary-color);
  background-color: var(--text-contrast);
  white-space: nowrap;
  padding: 10px;
  cursor: pointer;
  transition: 200ms;
  border: 1px var(--primary-color) solid;
}

.button2:hover {
  color: var(--text-contrast);
  background-color: var(--primary-color);
  white-space: nowrap;
  padding: 10px;
  border: 1px var(--primary-color) solid;
}

.pagingElements .button2:hover {
  color: var(--primary-color);
  background-color: var(--text-contrast);
  white-space: nowrap;
  cursor: pointer;
  transition: 200ms;
  border: 1px var(--primary-color) solid;
}

.textWithIcon {
  display: flex;
  justify-content: baseline;
  align-items: center;
  max-width: fit-content;
}


.textWithIcon svg {
  height: 40px;
  padding: 10px;
  transition: 200ms;
}

.textWithIcon svg:hover {
  scale: 1.25;
}

.textWithIcon:hover {
  text-decoration: underline;
}



.regularText {
  color: var(--text-color);
}

.defaultLink {
  color: var(--primary-color);
}

.defaultSection {
  margin-top: 30px;
  margin-bottom: 30px;
  box-sizing: border-box;
}

.headInfo {
  color: var(--primary-color);
  font-size: 150%;
  font-weight: bold;
}

.defaultInput {
  border: 0;
  border-bottom: 1px var(--primary-color) solid;
  width: 400px;
  outline: none;
  color: var(--text-color);
  text-align: center;
  background-color: #00000000;
}

.defaultTSInput {
  width: 50px;
}

.defaultInputContainer {
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 100px;
}

.centeredInputContainer {
  margin-right: 0px;
  justify-content: center;
}

.defaultInputContainer .defaultInput {
  margin-left: 10px;
}

.outerFlexContainer {
  display: flex;
  justify-content: center;
}

.hugeHeadline {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 400%;
  color: var(--primary-color);
  font-weight: bold;
  margin: 0;

}

.defaultCheckbox {
  background-color: #00000000;
  accent-color: var(--primary-color);
  width: 20px;
  height: 20px;
}

.countryClaimingChoice .defaultCheckbox {
  margin-left: 30px;
}


.defaultCenteredContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.componentsList {
  text-align: center;
}

header .button1 {
  border: 1px var(--text-contrast) solid;
}

header .button2 {
  border: 1px var(--text-contrast) solid;
}

header .button2:hover {
  color: var(--primary-color);
  background-color: var(--text-contrast);
  white-space: nowrap;
  padding: 10px;
  border: 1px var(--text-contrast) solid;
  cursor: pointer;
  transition: 200ms;
}

header .button1:hover {
  border: 1px var(--text-contrast) solid;
}

.navLogin .button1,
.navLogin .button2 {
  margin-left: 10px;
  margin-right: 10px;
}

.horizontalMargins {
  margin-left: 10px;
  margin-right: 10px;
}



.defaultSelect {
  background-color: #231d85;
}

.defaultSelect option:hover {
  background-color: #231d85;
}


.bigProfilePictureContainer {
  height: 200px;
  aspect-ratio: 1/1;
}



.profilePicture {
  height: 100%;
  aspect-ratio: 1/1;
  background-size: cover;
  background-position: center;
  border-radius: 100%;
  border: 2px var(--primary-color) solid;
  box-sizing: border-box;
  display: inline-block;
}

.navLogin .profilePicture {
  border: 2px var(--text-contrast) solid;

}

.pagingElement,
.pagingElement:hover {
  padding: 0;
  width: 40px;
  height: 40px;
  margin: 2px;
}

.pagingElements {
  display: flex;
  justify-content: center;
}

.photoBox {
  width: 200px;
  height: 200px;
  border: 2px solid var(--primary-color);
  background-size: cover;
}


.defaultLit {
  text-align: left;
}

.defaultFlag {
  height: 35px;
  border: 1px var(--primary-color) solid;

}

.choiceItem .defaultFlag {
  border: 1px var(--text-contrast) solid;
}

.choiceItem:hover .defaultFlag {
  border: 1px var(--primary-color) solid;
}

.loadingBackground {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  background-color: var(--text-contrast);
  opacity: 0.7;
  width: 100vw;
  height: 100vh;
}

.loadingContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  fill: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingContainer svg {
  animation: spin 5s linear infinite;
  width: 300px;
}



.countryLink {
  width: 380px;
  text-align: center;
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin: 5px;
  color: var(--text-color);
  margin-left: 10px;
}

.countryLink .defaultFlag {
  border: 1px solid var(--primary-color);
  margin-right: 15px;
  box-sizing: border-box;
  transition: 200ms;
}

.countryLink:hover .defaultFlag {
  scale: 1.25;
}

.columnContainer {
  display: flex;
  flex-direction: row;
  /* układa dzieci w pionie, jedno pod drugim */
}




.countryAdditionalInfo {
  position: sticky;
  top: 40%;
  padding: 10px;
  border: 1px solid var(--text-contrast);
}

.countryPhoto .hugeHeadline {
  z-index: 10;
  color: var(--text-contrast);
}

.hugeHeadline .defaultFlag {
  height: 80px;
  border: 1px solid var(--text-contrast);
  margin-right: 20px;
}

.countryLabelInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 150%;
  color: var(--text-contrast);
  font-weight: bold;
  margin: 0;
}

.countryLabelInfo .defaultFlag{
  height: 30px;
  border: 1px solid var(--text-contrast);
  margin-right: 20px;
}

 .votingCountry .defaultFlag{
  height: 50px;
  border: 1px solid var(--primary-color);
  margin-right: 20px;
}

.votingCountry {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 150%;
  color: var(--primary-color);
  font-weight: bold;
  margin: 0;
}

.countryInfoLeft {
  text-align: left;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.countryPhoto {
  height: 500px;
  background-size:  cover;
  background-position: center;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 10px;
  background-attachment: fixed;

}

.countryPhotoOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primary-color);
  opacity: 0.75;
  z-index: 0;
}

.songFrame {
  height: 200px;
  width: 100%;
}

.song {
  background-color: var(--primary-color);
  width: fit-content;
  padding: 20px;
  color: var(--text-contrast);
  width: 340px;

}

.entryTitleLabel {
  display: flex;
  align-items: center;
  color: var(--text-contrast);
}

.entryTitleLabel a {
  color: var(--text-contrast);
}

.entryTitleLabel .defaultFlag {

  margin-right: 10px;
  border: var(--text-contrast) solid 1px;
  transition: 200ms;
}

.entryTitleLabel .defaultFlag:hover {
  scale: 1.25;
}

.song2columns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alignCenter {
  display: flex;
  align-items: center;
}

.songEditionIcon {
  color: var(--primary-color);
  background-color: var(--text-contrast);
  width: 40px;
  height: 40px;
  text-align: center;
  justify-content: center;
  font-size: 120%;
  font-weight: bold;
  border-radius: 100%;
  transition: 200ms;
  cursor: pointer;
}

.songEditionIcon a {
  color: var(--primary-color);
}

.songEditionIcon:hover {
  scale: 1.25
}


.statsTable {
  border-collapse: collapse;
}

.noWrapTable td {
  white-space: nowrap;
}

.noWrap{
  white-space: nowrap;
}

.defaultTable {
  border-collapse: collapse;
  width: 100%;
}

.statsTable td {
  border: solid 1px var(--primary-color);
  padding: 5px;
}

.biggerInfo {
  font-size: 200%;
}

.flexColumns {
  display: flex;
  justify-content: stretch;
}

.defaultCountryWithFlag a {
  display: flex;
  align-items: center;
  color: var(--text-color);
}

.defaultCountryWithFlag {
  margin: 0;
}

.defaultCountryWithFlag a .defaultFlag {
  border: var(--primary-color) 1px solid;
  margin-right: 10px;
  transition: 200ms;
}

.defaultCountryWithFlag a .defaultFlag:hover {
  scale: 1.10;
}

.defaultTableCell {
  border: var(--primary-color) 1px solid;
  margin: 0;
  padding: 5px;

}

.placementCell {
  border: var(--text-contrast) 1px solid;
  background-color: var(--primary-color);
  font-size: 100%;
  font-weight: bold;
  color: var(--text-contrast);
  padding-left: 20px;
  padding-right: 20px;
}

.paddedCell {
  padding-left: 20px;
  padding-right: 20px;
}

.columnDiv {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.countryLinkFlag a .defaultFlag {
  border: var(--primary-color) solid 1px;
  transition: 200ms;
}

.countryLinkFlag a .defaultFlag:hover {
  scale: 1.10;
}

.margin_container {
  margin: 15px;
}

.marginsSection {
  margin-top: 76px;
  margin-bottom: 75px;
}

.defaultInputContainer .input_margin {
  margin-left: 10px;
}

.countryInline {
  display: inline-block;
}

.countryInline a {
  width: fit-content;
  color: var(--primary-color);
  font-weight: bold;
}

.borrower {
  display: flex;
  align-items: center;
  width: fit-content;
}

.borrowersContainer {
  width: fit-content;
}

.borrowersOuterContainer {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.choiceItem .profilePicture {
  width: 50px;
  height: 50px;
  border-color: var(--text-contrast);
  transition: 200ms;
}

.choiceItem:hover .profilePicture {
  border-color: var(--primary-color);
}


.editionCircle {
  background-color: var(--primary-color);
  color: var(--text-contrast);
  width: 50px;
  height: 50px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 120%;
  font-weight: bold;
  border-radius: 100%;
}


.claimedCountry a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: var(--text-color);

}

.claimedCountry .defaultFlag {
  margin-right: 10px;
  border: var(--primary-color) 1px solid;
  transition: 200ms;
}

.claimedCountry:hover .defaultFlag {
  scale: 1.15;
}


.claimedCountry .defaultFlag {
  margin-right: 10px;
  border: var(--primary-color) 1px solid;
}

.spanField {
  display: flex;
  align-items: center;

}

.messageTitle{
  color: var(--primary-color);
}

.message{
  border: var(--primary-color) 1px solid;
  padding: 20px;
  margin: 20px;
}

.adminRO{
  width: 50px;
  height: 50px;
  background-color: var(--primary-color);
  color: var(--text-contrast);
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border: var(--primary-color) 1px solid;

}



.televotingOuterConteiner{
  justify-content: center;
  display: flex;
}

.televotingInnerConteiner{
  text-align: left;
  justify-content: flex-start;
  width: fit-content;
}


.teleCountry{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 5px;
  cursor: pointer;
  transition: 200ms;
  color: var(--text-color);

}

.teleCountry:hover{
  scale: 1.05;

}

.teleCountry .defaultFlag{
  margin-right: 10px;
  border: var(--primary-color) 1px solid;
}


.checkedTele{
  width: 50px;
  height: 50px;
  background-color: var(--text-contrast);
  color: var(--primary-color);
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border: var(--primary-color) 1px solid;

}


.borderShown{
  border: var(--primary-color) 1px solid;

}

.defaultPadding{
  padding: 20px;
}

.editionHostCountry{
  width: min-content;
}

.textAlignCell{
  display: flex;
  justify-content: center;
}


.editionImage{
  max-width: 50vw;
}

.wrap, .hugeHeadline{
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.hugeHeadline .wrap{
  width: 100%;
}

.columnContainer{
  box-sizing: border-box;
  
}


.infoColumn {
  background-color: var(--primary-color);
  width: 20vw;
  text-align: center;
  padding: 20px;
  color: var(--text-contrast);
  box-sizing: border-box;
}


.columnContainer .defaultFormContainer{
  flex: 1 1 auto; /* kombinacja grow, shrink i basis */
  max-width: 100%; /* zapobiega przekraczaniu 100% szerokości rodzica */
  width: 80vw;
  
}

.userPhoto .hugeHeadline{
  max-width: 100%;
}

.hugeHeadline,.wrap{
  word-wrap: break-word;
}

.headInfo{
  width: 100%;
  word-wrap: break-word;
}
.hugeHeadline .wrap{
  font-size: 50px;
}

.userStatCell{
  display: flex;
  justify-content: center;
  align-items: center;
}

.userStatCell a .profilePicture{
  width: 50px;
  aspect-ratio: 1/1;
  margin-right: 10px;
}

.banerContainer{
  width: 100%;
  height: 700px;
  background-size: cover;
  background-position: center;
  position: relative;
  background-attachment: fixed;
}

.banerOverlay{
  background-color: var(--primary-color);
  height: 100%;
  width: 100%;
  opacity: 0.7;
}

.mainWebpageContent{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainWebpageContentLogoField{
  width: 900px;
}

.mainWebpageContentLogoField svg{
  fill: var(--text-contrast);
}

@media screen and (max-width: 1200px) {
  .banerContainer{
    width: 100%;
    height: 500px;
  
  }

  .mainWebpageContentLogoField{
    width: 80%;
  }

  
  .hideOnMobile{
    display: none;
  }

  .hugeHeadline .wrap{
    font-size: 30px;
  }
  

  .userPhoto {
    padding: 20px;
  }


  .columnContainer .defaultFormContainer{
    flex: 1 1 auto; /* kombinacja grow, shrink i basis */
    max-width: 100%; /* zapobiega przekraczaniu 100% szerokości rodzica */
    width: 100%;
    
  }


  .spanField {
    margin-top: 20px;
  }

  .countryClaimingChoice .defaultCheckbox {
    margin-left: 0px;
  }


  .editionCircle {
    margin-right: 0px;
  }

  .countryClaimingChoice .searchChoiceContainer {
    width: 100%;
  }

  .countryClaimingChoice {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;
  }

  .flexColumns {
    flex-direction: column;
  }

  .countryPhoto .hugeHeadline {
    font-size: 170%;
  }

  .countryPhoto {
    height: 400px;
  }

  .hugeHeadline .defaultFlag {
    height: 50px;
  }

  .columnContainer {
    display: flex;
    flex-direction: column;

    /* układa dzieci w pionie, jedno pod drugim */
  }

  .infoColumn {
    width: 100%;
    border-top: solid 2px var(--text-contrast);

  }

  .defaultInput {
    width: 100%;
  }

  .defaultTSInput{
    width: 50px;
  }

  .defaultSection .defaultInputContainer {
    width: 100%;
  }

  .defaultInputContainer {
    padding: 3px;
    text-align: center;
    display: inline-block;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .centeredInputContainer {
    margin-left: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
  }

  .hugeHeadline {
    font-size: 300%;
  }

  .defaultInputContainer .defaultInput {
    margin-left: 0px;
  }

  .borrower {
    flex-direction: column;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }

}