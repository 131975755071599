.defaultFormContainer {
    min-height: 70vh;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding-top: 5%;
    padding-bottom: 5%;
    box-sizing: border-box;
}

.secondFormContainer {
    padding-top: 0;
    min-height: 0;
}



.defaultForm {
    box-sizing: border-box;
    border: var(--primary-color) 1px solid;
    background-color: var(--text-contrast);
    text-align: center;
    padding: 20px;
    width: 60%;
}

.defaultWider{
    width: 80%;

}

.formLogo {
    width: 60%;
    padding: 50px;
    box-sizing: border-box;
    fill: var(--primary-color);
}


.formFields {
    display: flex;
    flex-direction: column;
    width: fit-content;
    text-align: end;
  }
  
.widePage{
    width: 90%;
    padding: 50px;
}


@media screen and (max-width: 1200px) {
    .defaultForm {
        box-sizing: border-box;
        border: 0px;
        text-align: center;
        padding: 20px;
        width: 100%;
    }

    .formLogo {
        width: 100%;
        padding: 30px;
        box-sizing: border-box;
        fill: var(--primary-color);
    }

    .formFields {
        margin-right: 0;
        padding: 0;
        width: 80%;
    }

    .defaultFormContainer {
        padding: 30px;
        padding-top: 0;
    }
}