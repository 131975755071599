header {
  background-color: var(--primary-color);
  color: var(--text-contrast);
  position: sticky;
  top: 0;
  z-index: 2000;
}

nav {
  width: 100%;
  display: flex;
  padding: 20px;
  box-sizing: border-box;
}

.navLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  fill: var(--text-contrast);
  transition: 200ms;
}


header {
  background-color: var(--primary-color);
  color: var(--text-contrast);
  position: sticky;
  top: 0;
  z-index: 2000;
}

nav {
  width: 100%;
  display: flex;
  padding: 20px;
  box-sizing: border-box;
}


.menuButtons {
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.menuLink {
  color: var(--text-contrast);
  padding: 10px;
  transition: 200ms;
}

.menuLink:hover {
  color: var(--text-contrast);
  padding: 10px;
  scale: 1.15;
  text-decoration: underline;
}

.navLogin {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 20%;
}

.navLoginIcon {
  display: flex;
  align-items: center;
  height: 60%;
  aspect-ratio: 1/1;
  margin-right: 10px;
  box-sizing: border-box;
  fill: var(--text-contrast);
  transition: 200ms;
  cursor: pointer;
}

.navLoginIcon svg{
  height: 100%;
  aspect-ratio: 1/1;
  transition: 200ms;

}

.navLoginIcon svg:hover{
  scale: 1.25;
}


@media screen and (max-width: 1200px) {
  header {
    position: inherit;
  }

  nav {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: auto;
  }

  .navLogo {
    width: 100%;
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 10px;
    box-sizing: border-box;
  }
  .navLogo svg{
    width: 400px;
  }

  .menuButtons {
    box-sizing: border-box;
    flex-direction: column;
    margin: 0;
    padding: 20px;
    box-sizing: border-box;
  }

  .navLogin {
    width: 100%;
    height: 60px;
    justify-content: center;
  }
}
