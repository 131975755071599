.searchChoiceContainer{
    position: relative;
}

.choiceItemsContainer{
    position: absolute;
    background-color: var(--primary-color);
    color: var(--text-contrast);
    left: 0;
    margin-top: 10px;
    width: 100%;
    border: 1px var(--text-contrast) solid;
    z-index: 100;
    max-height: 400px;
    overflow-y: scroll;
}

.choiceItem{
    display: flex;
    padding: 10px;
    border: 1px var(--text-contrast) solid;
    background-color: var(--primary-color);
    box-sizing: border-box;
    transition: 200ms;
    cursor: pointer;
}

.choiceItemText span, .choiceItemText{
    word-break:break-all;
    overflow-wrap: break-word;
}

.choiceItem:hover{
    background-color: var(--text-contrast);
    color: var(--primary-color);
    border: 1px var(--primary-color) solid;
}

.choiceItemText{
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
}

.searchNotFound{
    display: flex;
    padding: 10px;
    border: 1px var(--text-contrast) solid;
    background-color: var(--primary-color);
    box-sizing: border-box;
}
.chosenItem{
    border: 1px red solid;
    color: white;
    border: 0;
    width: 400px;
    outline: none;
    color: var(--text-contrast);
    border: 1px var(--primary-color) solid;
    text-align: center;
}
.defaultInputContainer .chosenItemMargin{
    margin-left: 10px;
    }

@media screen and (max-width: 1200px) {


    .chosenItem {
      width: 100%;
      margin-top: 10px;
      margin-left: 0;
    }

.defaultInputContainer .chosenItemMargin{
        margin-left: 0;
    }
  }