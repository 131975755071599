.templatesContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.templateContainer{
    margin: 10px;
}

.templateContainer p{
    margin: 5px;
}
.templateContainer a{
    color: var(--text-color);
}
.templateTitle{
font-weight: bold;
color: var(--primary-color);
}

.templateBox{
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    border-width: 2px;
    border-style: solid;
    background-size: cover;
    cursor: pointer;
}



.templateElement1, .templateElement2{
    padding: 20px;
    width: 70px;
    border-width: 2px;
    border-style: solid;
}

.photoLibraryContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.photoLibraryItem{
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.photoLibraryPicture{
    width: 150px;
    height: 150px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}