.tabContainer {
    width: 100%;
    border: 1px var(--primary-color) solid;
}

.tabContent {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
}

.tabsContainer {
    display: flex;

}


.tab {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--primary-color);
    color: var(--text-contrast);
    border: 1px var(--primary-color) solid;
    transition: 200ms;
}

.tab:hover:not(.active) {
    background-color: var(--text-contrast);
    color: var(--primary-color);
    border: 1px var(--text-contrast) solid;

}


.tab.active {
    cursor: auto;
    background-color: var(--text-contrast);
    color: var(--primary-color);
    border: 1px var(--text-contrast) solid;
}




@media screen and (max-width: 1200px) {

    .tabsContainer {
        flex-direction: column;
    }

    .tab{
        border: 0;
        border-bottom: 1px var(--primary-color) solid;
    }
    
    .tab:hover:not(.active) {
        border: 0;
        border-bottom: 1px var(--primary-color) solid;
    
    }
    
    .tab.active {
        border: 0;
        border-bottom: 1px var(--primary-color) solid;
    }
    

}