footer {
    width: 100%;
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    position: relative;
    bottom: 0;
    flex-shrink: 0;
}


.footerColumn {
    width: 50%;
}


.footerLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    transition: 200ms;
    overflow: hidden;
}

.footerLogo svg {
    transition: 200ms;
}

.footerLogo svg {
    fill: var(--text-contrast);
    width: 50%;
}

.footerLogo svg:hover {
    scale: 1.10;
}

.footerIcon {
    fill: var(--text-contrast);
}


footer .textWithIcon {
    color: var(--text-contrast);
    cursor: pointer;
    margin-left: 30px;
}

@media screen and (max-width: 1200px) {
    footer {
        flex-direction: column;
    }

    footer .textWithIcon {
        margin-left: 0px;
    }

    .footerColumn {
        width: 80%;
    }

    .footerLogo {
        padding: 10%;
    }

    .footerLogo svg {
        width: 80%;
    }

    .footerColumn {
        max-width: fit-content;
    }

}